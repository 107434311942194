<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              connect_without_contact
            </v-icon>
            Contacts
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text
          class="pt-4"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                volume_down
              </v-icon>

              Contacts
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Refine search results with only influencers that have Email or other contact details.
              </span>
            </v-tooltip>
          </div>

          <v-autocomplete
            v-model="form"
            :items="filteredSearchItems"
            label="Contacts"
            dense
            outlined
            :append-icon="null"
            return-object
            hide-details
            item-text="name"
            item-value="value"
            @change="submitForm"
            class="mt-3"
          ></v-autocomplete>

          <div v-if="appliedFilters.length" class="mt-3">
            <div
              v-for="item in appliedFilters"
              :key="item.id"
            >
              <div class="d-flex justify-space-between align-center pb-1 pt-3">
                {{ item.data.inputs.name }}

                <v-btn icon color="red" @click="removeFilterItem(item)">
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "contacts"

const originalForm = () => ({
  name: null,
  value: null
})

export default {
  name: "FilterContacts",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    searchItems: [
      {
        name: "Has Email",
        value: "email",
      },
      {
        name: "Has Phone",
        value: "phone",
      },
      {
        name: "Has Snapchat",
        value: "snapchat",
      },
      {
        name: "Has Facebook",
        value: "facebook",
      },
      {
        name: "Has YouTube",
        value: "youtube",
      },
      {
        name: "Has TikTok",
        value: "tiktok",
      },
      {
        name: "Has Twitter",
        value: "twitter",
      },
      {
        name: "Has WhatsApp",
        value: "whatsapp",
      },
      {
        name: "Has LinkedIn",
        value: "lineid",
      },
      {
        name: "Has VK",
        value: "vk",
      },
      {
        name: "Has BBM",
        value: "bbm",
      },
      {
        name: "Has Kik",
        value: "kik",
      },
      {
        name: "Has WeChat",
        value: "wechat",
      },
      {
        name: "Has Viber",
        value: "viber",
      },
      {
        name: "Has Skype",
        value: "skype",
      },
      {
        name: "Has Tumblr",
        value: "tumblr",
      },
      {
        name: "Has TwitchTV",
        value: "twitchtv",
      },
      {
        name: "Has Kakao",
        value: "kakao",
      },
      {
        name: "Has Pinterest",
        value: "pinterest",
      },
      {
        name: "Has LinkTree",
        value: "linktree",
      },
      {
        name: "Has Sarahah",
        value: "sarahah",
      },
      {
        name: "Has Sayat",
        value: "sayat",
      },
      {
        name: "Has iTunes",
        value: "itunes",
      },
      {
        name: "Has Weibo",
        value: "weibo",
      },
    ]
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // get a list of all of them
    appliedFilters() {
      return this.$store.getters["influencerDiscovery/findFiltersByType"](filterName)
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    },

    filteredSearchItems() {
      return this.searchItems.filter((item) => {
        return !Boolean(
          this.appliedFilters.find((search) => {
            return search.data.inputs.value ===  item.value
          })
        )
      })
    }
  },

  validations: {
    form: {
      audience: {},
      influencer: {}
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText() {
      return `Contact: ${this.form.name}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store*
     */
    async submitForm() {
      await this.$v.form.$touch()

      if (this.$v.form.$anyError) return

      this.$store.dispatch("influencerDiscovery/addFilter", {
        type: filterName,
        data: {
          color: "amber lighten-5",
          icon: "connect_without_contact",
          iconColor: "amber",
          text: this.computeFilterText(),
          inputs: {
            ...this.form
          }
        }
      })

      // finally hide the modal
      this.shouldShowModal = false
      this.form = originalForm()
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$store.dispatch("influencerDiscovery/removeFilter", item.id)
    },
  },
}
</script>
